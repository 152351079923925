<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="10">
        <v-form>
          <v-card class="pa-4">
            <v-card-title>Send SMS </v-card-title>
            <v-divider class="mb-6 mx-4"></v-divider>

            <v-text-field
              label="Ttile"
              v-model="title"
              placeholder="Title*"
              :rules="$requiredRules"
              filled
              dense
            ></v-text-field>

            <v-autocomplete
              v-model="selected_rooms"
              :rules="$requiredRules"
              :items="rooms"
              item-text="name"
              item-value="id"
              label="Class Room"
              multiple
            />

            <v-select
              v-model="message"
              :items="template_data"
              item-text="title"
              item-value="content"
              :rules="$requiredRules"
              label="Select a Template"
              single-line
            >
            </v-select>

            <v-textarea
              :maxlength="this.max_count"
              label="Compose"
              :rules="rules"
              v-model="message"
              :value="value"
            ></v-textarea>
            <!-- v-model="message" -->
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile>
                  Character count:{{ totalCount }} /{{ max_count }}
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile>
                  Remaining : {{ hasRemaining }}
                </v-card>
              </v-col>
              <v-col cols="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile v-model="sms_count">
                  SMS count : {{ smsCount }}
                </v-card>
              </v-col>
            </v-row>
            <v-row style="background-color: azure">
              <v-row>
                <v-col>
                  <v-checkbox v-model="schedule" label="Schedule"></v-checkbox>
                </v-col>
                <v-col style="align: left">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        label="Select Date(s)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => v.length > 0 || 'Select at least one date',
                        ]"
                        v-on="on"
                      ></v-combobox>
                    </template>

                    <v-date-picker
                      v-model="dates"
                      :rules="$requiredRules"
                      :max="four_weeks_from_today"
                      :min="today"
                      no-title
                      scrollable
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :rules="$requiredRules"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start"
                        label="Schedule Time"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="$requiredRules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      ampm-in-title
                      format="ampm"
                      color="red lighten-1"
                      v-model="start"
                      :max="end"
                      @click:minute="$refs.menu2.save(start)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-select :items="scheduleData" label="Select one"></v-select>
                </v-col>
              </v-row>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="8" lg="7"> </v-col>
              <v-col cols="6" sm="6" md="1" lg="3">
                <v-btn
                  class="pa-2"
                  tile
                  @click="sendDraft()"
                  color="primary"
                  block
                  >Save as Draft</v-btn
                >
              </v-col>
              <v-col cols="6" md="1" lg="1">
                <!-- <v-btn
                  class="pa-2"
                  tile
                  v-if="max_count >= 160"
                  @click="sendSMS()"
                  color="primary"
                  block
                  >Send Now</v-btn
                > -->
                <v-btn
                  class="pa-2"
                  v-if="!schedule"
                  @click="sendSMS()"
                  color="primary"
                  block
                >
                  Send Now
                </v-btn>
                <v-btn
                  v-else
                  class="pa-2"
                  @click="sendSMS()"
                  color="primary"
                  block
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      dates: [] || "",
      scheduleData: [
        "Select None",
        "Every day",
        "Every week same day",
        "Every Month same day",
      ],
      schedule: false,
      template_data: [],
      template_value: [],
      sms_send: false,
      sms_count: null,
      start: null,
      end: null,
      phone: "",
      menu: false,
      menu2: false,
      menu3: false,
      max_count: 800,
      message: "",

      title: "",
      is_draft: false,
      name: "",
      rooms: [],
      selected_rooms: [],
      selectedTemplate: "",
      remainData: null,
    };
  },
  watch: {
    directToTemplate(e) {
      // this.message=selectedTemplate.content;
      alert(e);
    },
  },
  computed: {
    classes() {
      return this.$store.state.classes.classes;
    },
    four_weeks_from_today() {
      const date = new Date();
      date.setDate(date.getDate() + 84);
      return new Date(date - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    today() {
      return new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    totalCount() {
      return this.message.length;
    },
    smsCount() {
      return parseInt(this.message.length / 160);
    },

    hasRemaining() {
      return this.max_count - this.totalCount;
    },
  },

  mounted() {
    this.loadTemplate();
    this.loadOwnedRooms();
    this.loadRemain();
  },

  methods: {
    loadTemplate() {
      this.$api.get(`/active_template/`).then((response) => {
        this.template_data = response.data.results;
      });
    },
    loadRemain() {
      this.$api.get("mainsms/remain_sms/").then((res) => {
        this.remainData = res.data;
      });
    },

    loadOwnedRooms() {
      this.$api.get("/rooms/").then((res) => {
        this.rooms = res.data.results;
      });
    },

    sendSMS() {
      const remainData = parseInt(this.remainData);
      if (remainData >= this.message.length) {
        if (this.schedule === true) {
          const dateObj = new Date(this.dates);
          const year = dateObj.getFullYear();
          const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
          const date = dateObj.getDate().toString().padStart(2, "0");
          const formattedDate = year + month + date;
          const data = {
            title: this.title,
            sms_count: this.smsCount,
            message: this.message,
            getRooms: this.selected_rooms,
            sms_send: false,
            dates: formattedDate,
            // dates:this.dates,
            schedule: this.schedule,

            start: this.start,
          };

          this.$api.post("mainsms/", data);
          this.$router.push("/organization/sms/list/");
        }
      } else if (this.schedule === false) {
        const data = {
          title: this.title,
          sms_count: this.smsCount,
          message: this.message,
          getRooms: this.selected_rooms,
          sms_send: true,
          dates: formattedDate,
          // dates:this.dates,
          schedule: this.schedule,

          start: this.start,
        };

        this.$api.post("mainsms/", data);
        this.$router.push("/organization/sms/list/");
      } else {
        alert("Sorry, you have not available SMS quantity!\nPlease buy SMS.");
      }
      // alert("Sorry you are not Authorized to send SMS");
    },

    sendDraft() {
      if (this.schedule === true) {
        const data = {
          title: this.title,

          message: this.message,
          getRooms: this.selected_rooms,
          sms_send: false,
          is_draft: true,
          dates: moment(new Date(this.dates)).format("YYYYMMDD"),
          // dates:this.dates,
          schedule: this.schedule,
          sms_user: this.selected_rooms,

          start: this.start,
        };

        this.$api.post("draft/", data);
        this.$router.push("/organization/sms/draft/");
      } else {
        const data = {
          title: this.title,

          message: this.message,
          getRooms: this.selected_rooms,
          sms_send: false,
          dates: null,
          is_draft: true,
          // dates:this.dates,
          schedule: this.schedule,
          sms_user: this.selected_rooms,

          start: this.start,
        };

        // const data = {
        //   title: this.title,
        //   message: this.message,
        //   sms_user: this.selected_rooms,

        //   dates: moment(new Date(this.dates)).format("YYYYMMDD"),
        // };
        this.$api.post("draft/", data);

        this.$router.push("/organization/sms/draft/");
      }
    },
  },
};
</script>

<style></style>
